import React from "react"

import { NavBox, NavTitle, Day, StyledLink, TopicBox, ExternalLink } from "./rightNavComponents"

const WeekOneNav = props => (
    <NavBox>
        <NavTitle />
        <Day>
            <StyledLink location="/week1/day1" name="Day 1" />
            <TopicBox>
                <ExternalLink type="lecture" name="Command Line" link="https://docs.google.com/presentation/d/1qiGYkocBKoIZhhRmlQcXvfbn4EzhgPUDfEGxabjk79s/edit?usp=sharing" />
                <ExternalLink type="lecture" name="Variables" link="https://docs.google.com/presentation/d/1IChb4s7WiOt0TNAZ8_fwT855vvYYoWV33lFf-xWQ748/edit?usp=sharing" />
            </TopicBox>
        </Day>
        <Day>
            <StyledLink location="/week1/day2" name="Day 2" />
            <TopicBox>
                <ExternalLink type="lecture" name="User Input" link="https://docs.google.com/presentation/d/1l9bRNaw71yIInHlnB8EU7gf0Dva1ZUP1YuWMPNIzhVg/edit?usp=sharing" />
                <ExternalLink type="lecture" name="Array" link="https://docs.google.com/presentation/d/1eB0_ozo3SnIlpvvrd056mEgVnfeecANqSUVW_Oove_g/edit?usp=sharing" />
                <ExternalLink type="lecture" name="Random" link="https://docs.google.com/presentation/d/1T4ppns9WU7Nt5lOjwNB4tkxURB8dt0REzRooQDAAMKc/edit?usp=sharing" />
            </TopicBox>
        </Day>
        <Day>
            <StyledLink location="/week1/day3" name="Day 3" />
            <TopicBox>
                <ExternalLink type="lecture" name="Conditionals" link="https://drive.google.com/open?id=1648UT-SFNYlz3ZnTmbzqcC08yt49ter5l3g-OjdxZOU" />
                <ExternalLink type="lecture" name="While Loops" link="https://docs.google.com/presentation/d/1oJd5X80z2Ar_QPaQJaHX_U72ZqmD3koQjSXAoRZSstc/edit?usp=sharing" />
                <ExternalLink type="lecture" name="For Loops" link="https://docs.google.com/presentation/d/1bOc8hcy86fkglbv1jPqSBZ-9irlcTqvvnndTmlhDW8Q/edit?usp=sharing" />
            </TopicBox>
        </Day>
        <Day>
            <StyledLink location="/week1/day4" name="Day 4" />
            <TopicBox>
                <ExternalLink type="lecture" name="Functions" link="https://docs.google.com/presentation/d/13oMNOcmrdEdcDjArha3Fg4TR0Yet4bi5ePEqmdNmxWw/edit?usp=sharing" />
            </TopicBox>
        </Day>
        <Day>
            <StyledLink location="/week1/day5" name="Day 5" />
            <TopicBox>
                <ExternalLink type="" name="M.A.S.H." link="https://docs.google.com/document/d/18NSO7zbcKAQ_oB1kHCy8x5oU3oszd7DC8X05WdTRlBk/edit?usp=sharing" />
            </TopicBox>
        </Day>
    </NavBox>
)

export default WeekOneNav;