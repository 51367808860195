import React from "react";
import styled from "@emotion/styled";


const Overlaybg = styled.div`
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    display: flex;
    flex-direction:column;
    place-content: center center;
`
const Overlaytext = styled.span`
    font-size: 48px;
    font-weight: bold;
    font-family: 'Inconsolata', monospace;
    color: white;
    animation: CoolFlicker 1.55s infinite linear;
`
const Gif = styled.div`
    height: 500px;
    width: 500px;
    background-image: url('https://giphy.com/embed/o0vwzuFwCGAFO');
`
const Box = styled.div`
    display: flex;
    flex-direction: column;
    place-items: center center;
    place-self: center center;
`


const Overlay = () => (
    <Overlaybg>
        <Box>
            <Gif />
            <Overlaytext>The schedule for this week will be available during the SI.</Overlaytext>
        </Box>
    </Overlaybg>
)

export default Overlay;