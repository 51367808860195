import React from "react";
import styled from "@emotion/styled";
import { graphql } from "gatsby";

import Layout from "../components/layout";

import WeekOneNav from "../components/rightnavs/weekoneRnav";
import DayScheduleWrapper from "../components/schedule_components/wrapper";
import DayTitleArea from "../components/schedule_components/daytitle";
import ScheduleContainer from "../components/schedule_components/schedulecontainer";
import { Project, Lecture, Misc, Coreskill, Review, Survey } from "../components/schedule_components/allactivitytypes";

import Overlay from "../components/wipoverlay.js";

let count = 0;

const Search = styled.input`
  border: solid 1px #23fcc7;
  /* height: 50px; */
  font-size: 25px;
  margin-bottom: 25px;
  background-color: transparent;
  width: 500px;
  color: #fff;
`;

class ComponentGod {
  createProject(sarr){
      let ret = <Project version={sarr[0].node.Sched_Ver} key={count} activitymateriallink={sarr[0].node.Link=== "wip" ? '' : sarr[0].node.Link}  activityname={sarr[0].node.Name_of_Activity} activityres1link={sarr[0].node.Link_2 !== "" ? sarr[0].node.Link_2 : '' } activityres1name={sarr[0].node.Link_2_Name !== "" ? sarr[0].node.Link_2_Name : ''} activityres2link={sarr[0].node.Link_3 !== "" ? sarr[0].node.Link_3 : ''} activityres2name={sarr[0].node.Link_3_Name !== "" ? sarr[0].node.Link_3_Name : ''}  activitydesc={sarr[0].node.Description}  />;
      count++;
      return ret;
  }

  createCoreSkill(sarr){
      let ret = <Coreskill version={sarr[0].node.Sched_Ver} key={count} activitymateriallink={sarr[0].node.Link=== "wip" || sarr[0].node.Link=== "dne" ? '' : sarr[0].node.Link}  activityname={sarr[0].node.Name_of_Activity} activityres1link={sarr[0].node.Link_2 !== "" ? sarr[0].node.Link_2 : '' } activityres1name={sarr[0].node.Link_2_Name !== "" ? sarr[0].node.Link_2_Name : ''} activityres2link={sarr[0].node.Link_3 !== "" ? sarr[0].node.Link_3 : ''} activityres2name={sarr[0].node.Link_3_Name !== "" ? sarr[0].node.Link_3_Name : ''}  activitydesc={sarr[0].node.Description}  />;
      count++;
      return ret;
  }

  createLecture(sarr){
      let ret = <Lecture version={sarr[0].node.Sched_Ver} key={count} activitymateriallink={sarr[0].node.Link=== "wip" || sarr[0].node.Link=== "dne" ? '' : sarr[0].node.Link}  activityname={sarr[0].node.Name_of_Activity} activityres1link={sarr[0].node.Link_2 !== "" ? sarr[0].node.Link_2 : '' } activityres1name={sarr[0].node.Link_2_Name !== "" ? sarr[0].node.Link_2_Name : ''} activityres2link={sarr[0].node.Link_3 !== "" ? sarr[0].node.Link_3 : ''} activityres2name={sarr[0].node.Link_3_Name !== "" ? sarr[0].node.Link_3_Name : ''}  activitydesc={sarr[0].node.Description}  />;
      count++;
      return ret;
  }

  createReview(sarr){
      let ret = <Review version={sarr[0].node.Sched_Ver} key={count} activitymateriallink={sarr[0].node.Link=== "wip" || sarr[0].node.Link=== "dne" ? '' : sarr[0].node.Link}  activityname={sarr[0].node.Name_of_Activity} activityres1link={sarr[0].node.Link_2 !== "" ? sarr[0].node.Link_2 : '' } activityres1name={sarr[0].node.Link_2_Name !== "" ? sarr[0].node.Link_2_Name : ''} activityres2link={sarr[0].node.Link_3 !== "" ? sarr[0].node.Link_3 : ''} activityres2name={sarr[0].node.Link_3_Name !== "" ? sarr[0].node.Link_3_Name : ''}  activitydesc={sarr[0].node.Description}  />;
      count++;
      return ret;
  }


  createMisc(sarr){
      function getCorrectDesc(a){
          switch(a){
              case "OFFICE HOURS":
                  return "Office hours is an extremely valuable resource. If you feel like there was a topic that was difficult, or you had a question that wasn’t answered, wanted to spend more time on a project, or even just want to use the room as a workspace, then office hours are for you! You’re strongly encouraged to use office hours to your advantage, you won’t regret it!";
              case "BREAK":
                  return "Break Time! Sit back and relax 😎";
              case "LUNCH":
                  return "Lunch time! Enjoy your food, get to know some of your peers, have fun!";
              default:
                return "";
          }
      }
      let miscDesc = getCorrectDesc(sarr[0].node.Name_of_Activity);
      let ret = <Misc version={sarr[0].node.Sched_Ver} key={count} activitymateriallink="https://docs.google.com/document/d/1d_rmRQVvPwzWBUh2FZV6iHhGR_TASQcAeufTKkw452I/edit?usp=sharing"  activityname={sarr[0].node.Name_of_Activity} activityres1link={sarr[0].node.Link_2 !== "" ? sarr[0].node.Link_2 : '' } activityres1name={sarr[0].node.Link_2_Name !== "" ? sarr[0].node.Link_2_Name : ''} activityres2link="" activityres2name="" activitydesc={miscDesc} />;
      count++;
      return ret;
  }

  createSurvey(sarr){
      let ret = <Survey version={sarr[0].node.Sched_Ver} key={count} activitymateriallink=""  activityname={sarr[0].node.Name_of_Activity} activityres1link="" activityres1name="" activityres2link="" activityres2name="" activitydesc={sarr[0].node.Description} />;
      count++;
      return ret;
  }
}

const cGod = new ComponentGod();

export default ({ data, pageContext }) => {
    const csvData = data.allSi2020SSoTCsv.edges;
    const day = pageContext.day;
    let week, dayNum, dayTitle;


    if(day <= 5){
      week = '1';
    } else if (day <= 10){
      week = '2';
    } else if (day <= 15){
      week = '3';
    } else if (day <= 20){
      week = '4';
    } else if (day <= 25){
      week = '5';
    } else if (day <= 30){
      week = '6';
    } else {
      week = 'invalid_day'
    }


    if(day < 10){
      dayNum = `0${day}`;
    }
    else {
      dayNum = day;
    }

    dayTitle = 'The Appendix';

    const tabName = `Appendix`;
    const currentData = csvData.filter(row => row.node.Sched_Ver === 'mo');
    const compressed = [];
    let temp = [];

    for(let i = 0; i < currentData.length; i++){
      if(temp.length === 0){
        temp.push(currentData[i]);
        continue;
      }
      if(currentData[i].node.Activity_Type === currentData[i-1].node.Activity_Type){
        if(currentData[i].node.Activity_Type === "Core Skill"){
          if(currentData[i].node.Name_of_Activity === currentData[i-1].node.Name_of_Activity){
            temp.push(currentData[i]);
          }
          else {
            compressed.push(temp);
            temp = [];
            temp.push(currentData[i]);
          }
        }
        else {
          temp.push(currentData[i]);
        }
      }
      if(currentData[i].node.Activity_Type !== currentData[i-1].node.Activity_Type){
        compressed.push(temp);
        temp = [];
        temp.push(currentData[i]);
      }
    }

    const concatenated = compressed.map((row, index) => {
      switch (row[0].node.Activity_Type) {
        case "Core Skill":
          return cGod.createCoreSkill(row);
        case "Project":
          return cGod.createProject(row);
        case "Misc":
          return null;
        case "Lecture / Codealong":
          return cGod.createLecture(row);
        case "Review":
          return cGod.createReview(row);
        case "Survey":
          return cGod.createSurvey(row);
        default:
          return null;
      }
    });

    // Final return statements
    if(dayTitle === "WIP"){
      return (
        <Overlay>
        </Overlay>
      )
    } else {
      return (
        <Layout rightnav={day === "1" ? <WeekOneNav/> : null}>
          <DayScheduleWrapper>
            <DayTitleArea dateText={`Search For Any Activity!`} dayofweek={day} weeknum={week} tabtitle={tabName} daynum={dayNum} titleText={dayTitle} />
            <Search id={`sBar`} />
            <ScheduleContainer>
              {concatenated}
            </ScheduleContainer>
          </DayScheduleWrapper>
        </Layout>
      )
    }
}




export const query = graphql`
query {
    allSi2020SSoTCsv {
      edges {
        node {
          Activity_Type
          Date
          Day
          Description
          Start_Time
          End_Time
          Link
          Link_2
          Link_2_Name
          Link_3
          Name_of_Activity
          Link_3_Name
          Sched_Ver
        }
      }
    }
  }
`